import './styles/bootstrap.scss'
import './App.scss'

import React, { useState, useEffect } from 'react'

import Header from './components/Header'
import Intro from './components/Intro'
import Why from './components/Why'
import Gallery from './components/Gallery'
import Qualification from './components/Qualification'
import Reward from './components/Reward'
import Timeline from './components/Timeline'
import Join from './components/Join'
import Sponsor from './components/Sponsor'
import Footer from './components/Footer'
import Winner from './components/Winner'


function YSCApp() {

  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace('https://patois.com/youngscout')
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  return <></>;

  // return (
  //   <div className='site-wrapper'>
  //     <Header />
  //     <Intro />
  //     <Why />
  //     <Gallery />
  //     <Qualification />
  //     <Reward />
  //     <Timeline />
  //     <Join />
  //     <Winner />
  //     <Sponsor />
  //     <Footer />
  //   </div>
  // )
}

export default YSCApp
